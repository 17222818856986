import * as React from 'react';
import { AppleButtonWrap } from '@features/login/components/buttons/styles/button.style';
import AppleIcon from '@icons/sns/apple.svg';

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const AppleButton = ({ onClick, disabled = false }: Props): JSX.Element => {
  return (
    <AppleButtonWrap
      disabled={disabled}
      onClick={onClick}
      data-testid="apple-button"
    >
      <AppleIcon />
      <span>Apple로 로그인</span>
    </AppleButtonWrap>
  );
};
export default AppleButton;
