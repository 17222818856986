import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSpring } from '@react-spring/web';
import useAppRouter from '@hooks/useAppRouter';
import {
  LoginButtonWrap,
  LoginPageWrap,
  Title,
} from '@features/login/styles/index.style';
import Logo from '@icons/logo/logo-black-h24.svg';
import KakaoButton from '@features/login/components/buttons/KakaoButton';
import AnonymousButton from '@features/login/components/buttons/AnonymousButton';
import AppleButton from '@features/login/components/buttons/AppleButton';
import OauthApi from '@src/apis/oauthApi';
import useUserInfoStore from '@hooks/useUserInfoStore';
import { usePushAgreePopupStore } from '@src/stores/popups';
import Text from '@components/common/Text';
import { googleTagManager } from '@src/tags';

const LoginContent: React.FC = () => {
  const router = useAppRouter();
  const { anonymous4cutRankingApply, redirectUri } = router.query as Record<
    string,
    string
  >;
  const [isMount, setMount] = useState(false);
  const { setPushAgreePopupHidden } = usePushAgreePopupStore();
  const [isLoading, setIsLoading] = useState(false);

  const [userInfo] = useUserInfoStore();
  const [animation] = useSpring(() => ({
    from: { y: 100, height: '100%' },
    to: { y: 0, height: '100%' },
  }));

  const handleClickKakaoButton = async () => {
    setIsLoading(true);
    googleTagManager.Login_Kakao();
    if (window.Kakao.isInitialized()) {
      window.Kakao.Auth.authorize({
        redirectUri: `${process.env.NEXT_PUBLIC_BASE_URL}/api/oauth2/authorization/kakao/redirect`,
        state: `${window.location.protocol}//${
          window.location.host
        }/login/complete/kakao${
          redirectUri ? `?redirectUri=${redirectUri}` : ''
        }`,
      });
      await new Promise((resolve) => {
        setTimeout(() => {
          setIsLoading(false);
          resolve(true);
        }, 4000);
      });
    }
  };

  const handleClickAppleButton = async () => {
    setIsLoading(true);
    googleTagManager.Login_Apple();
    const { data } = await OauthApi.postAppleLoginForm({
      redirectUri: redirectUri || '',
    });
    window.location.href = data.loginFormUrl;
    await new Promise((resolve) => {
      setTimeout(() => {
        setIsLoading(false);
        resolve(true);
      }, 4000);
    });
  };

  const handleClickAnonymousButton = () => {
    // 비회원 포토카드 등록
    router.push('/4cut-ranking/apply');
  };

  useEffect(() => {
    if (userInfo?.userId) router.back();
  }, [userInfo?.userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMount(true);

    // 다시 로그인 하는 경우, 프로모션 알림 동의 팝업 발생시켜야함
    setPushAgreePopupHidden(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isMount) return null;
  return (
    <>
      <LoginPageWrap style={animation}>
        <Title>
          <Logo />
          <Text typo="body1" color="gray60">
            지금 로그인하고 포토그레이가 제공하는
            <br />
            다양한 서비스를 경험해 보세요.
          </Text>
        </Title>
        <LoginButtonWrap>
          <KakaoButton disabled={isLoading} onClick={handleClickKakaoButton} />
          <AppleButton disabled={isLoading} onClick={handleClickAppleButton} />
          {anonymous4cutRankingApply && (
            <AnonymousButton onClick={handleClickAnonymousButton} />
          )}
        </LoginButtonWrap>
      </LoginPageWrap>
    </>
  );
};

export default LoginContent;
