import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { getColorValue } from '@styles/styleUtils';

export const LoginPageWrap = styled(animated.div)`
  ${({ theme }) => theme.layout.width};
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${getColorValue('white')};
  padding: 16px 20px 44px;
`;

export const Title = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
`;

export const LoginButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`;
