import styled from 'styled-components';

const Button = styled.button`
  border-radius: 12px;
  padding: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  position: relative;

  &:disabled {
    opacity: 0.4;
  }

  > svg {
    position: absolute;
    left: 14px;
  }
`;

export const KakaoButtonWrap = styled(Button)`
  background: #fee500;

  > span {
    color: #000;
  }
`;

export const AnonymousButtonWrap = styled(Button)`
  background: #404040;
  color: #fff;
`;

export const AppleButtonWrap = styled(Button)`
  background: #000212;

  > span {
    color: #fff;
  }
`;
