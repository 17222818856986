import { type GetServerSideProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import LoginContent from '@features/login';

const LoginPage: NextPage = () => <LoginContent />;

export const getServerSideProps: GetServerSideProps = async ({
  locale = 'ko',
}) => ({
  props: {
    ...(await serverSideTranslations(locale)),
  },
});

export default LoginPage;
