import * as React from 'react';
import { AnonymousButtonWrap } from '@features/login/components/buttons/styles/button.style';

interface Props {
  onClick: () => void;
}

const AnonymousButton: React.FC<Props> = ({ onClick }) => {
  return (
    <AnonymousButtonWrap onClick={onClick} data-testid="anonymous-button">
      <span>비회원으로 시작하기</span>
    </AnonymousButtonWrap>
  );
};

export default AnonymousButton;
