import * as React from 'react';
import { KakaoButtonWrap } from '@features/login/components/buttons/styles/button.style';
import KakaoIcon from '@icons/sns/kakao.svg';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const KakaoButton: React.FC<Props> = ({ onClick, disabled = false }) => {
  return (
    <KakaoButtonWrap
      disabled={disabled}
      onClick={onClick}
      data-testid="kakao-button"
    >
      <KakaoIcon />
      <span>카카오로 3초만에 시작하기</span>
    </KakaoButtonWrap>
  );
};

export default KakaoButton;
