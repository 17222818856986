import axiosInstance from '@src/axios';

interface LoginFormType {
  code: string;
  redirectUri: string;
}

const postKakaoCode = async (
  data: LoginFormType,
): Promise<
  AInstance.Res<{
    accessToken: string;
    refreshToken: string;
    firstLogin?: boolean;
  }>
> => axiosInstance.post('/api/oauth2/token/kakao', data);

interface AppleLoginFormType {
  appleIdToken: string;
  nickname?: string;
}

const postAppleCode = async (
  data: AppleLoginFormType,
): Promise<
  AInstance.Res<{
    accessToken: string;
    refreshToken: string;
    firstLogin?: boolean;
  }>
> => axiosInstance.post('/api/oauth2/token/apple', data);

const postAppleLoginForm = async (data?: {
  redirectUri: string;
}): Promise<AInstance.Res<{ loginFormUrl: string }>> =>
  axiosInstance.post('/api/oauth2/authorization/apple/login-form', {
    state: `${window.location.protocol}//${
      window.location.host
    }/login/complete/apple${
      data?.redirectUri ? `?redirectUri=${data.redirectUri}` : ''
    }`,
  });

const postSnsCode = async (data: {
  provider: SnsType;
  code: string;
  redirectUri: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post('/api/users/connect/profile', data);

const deleteSnsLink = async (data: {
  provider: SnsType;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.delete('/api/users/connect/profile', { data });

const postPhoneCertificate = async (
  data?: FormData,
): Promise<AInstance.DefaultRes> =>
  axiosInstance.post('/kcp/smartcert_proc_req', data);

export default {
  postKakaoCode,
  postAppleCode,
  postAppleLoginForm,
  postSnsCode,
  deleteSnsLink,
  postPhoneCertificate,
};
